import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Confirmation } from "page_components/contact"

const Contact = () => {
  const title = "Podziękowanie"
  return (
    <Layout
      seo={{
        title: title,
        description:
          "Inwestycje w apartamenty premium. Zarabiaj inwestując w apartamenty inwestycyjne, hotelowe, komercyjne.",
      }}
    >
      <Breadcrumbs title={title} />
      <PageHeader title="Twoja wiadomość została wysłana" />
      <Confirmation isDefault />
    </Layout>
  )
}

export default Contact
